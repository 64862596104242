import logo from "../Images/indie-logo.png";

const Header = () => {
    return (
        <div className="bg-black p-10 flex justify-between items-center h-16 z-20">
            <img src={logo} alt="Your Logo" className="w-auto mr-4 max-w-[50%]" />
            <button
                className="btn btn-success scale-75 md:scale-100 sm:scale-60 rounded-3xl mt-2 bg-custom-green shadow-md justify-end "
                onClick={() => window.open("https://indielist.ie/schedule", "_blank", "noreferrer")}
            >
                Book a call
            </button>
        </div>
    );
}

export default Header;


