import { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import roleSet from './list';

const RoleList = ({ setRole }: { setRole: (role: string) => void }) => {
  const [selected, setSelected] = useState('i.e. Copywriter with B2B Experience');
  const [query, setQuery] = useState('');
  const [color, setColor] = useState('gray-200');

  const filteredRoles =
    query === ''
      ? roleSet
      : roleSet.filter((role) =>
          role.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <div>
      <Combobox value={selected} onChange={(e) => {
        setSelected(e);
        setRole(e);
        setColor('black');
      }}>
        <div className="relative mb-5">
          <label className="label">
            <span className="label-text text-white">Required Freelancer Role:</span>
          </label>

          <div className="relative rounded-3xl w-full cursor-default overflow-hidden text-left shadow-md bg-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input style={{color: color}}
              className="min-w-[100%] combobox-input bg-white border-none py-2 pl-3 pr-10 text-sm leading-5 input input-bordered input-success max-w-xs  focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 bg-white">
              <ChevronUpDownIcon
                className="h-5 w-5 text-white-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredRoles.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredRoles.map((role) => (
                  <Combobox.Option
                    key={role.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-custom-green text-white' : 'text-gray-900'
                      }`
                    }
                    value={role.name} // Use role.name as the value to render
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {role.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export default RoleList;
