const roleSet = [
  { id: 1, name: 'Copywriter' },
  { id: 2, name: 'Graphic Designer' },
  { id: 3, name: 'Marketing Consultant' },
  { id: 4, name: 'Marketing Strategist' },
  { id: 5, name: 'Digital Marketing Specialist' },
  { id: 6, name: 'Video Production' },
  { id: 7, name: 'E-mail Marketeer' },
  { id: 8, name: 'Creative Director' },
  { id: 9, name: 'Account Management' },
  { id: 10, name: 'Art Director' },
  { id: 11, name: 'Project Manager' },
  { id: 12, name: 'Brand Strategist / Planner' },
  { id: 13, name: 'Social Media Specialist' },
  { id: 14, name: 'Brand Designer' },
  { id: 15, name: 'Photographer' },
  { id: 16, name: 'Web Designer' },
  { id: 17, name: 'Content Manager' },
  { id: 18, name: 'Public Relations Strategist' },
  { id: 19, name: 'Motion Graphics Designer' },
  { id: 20, name: 'Digital Designer' },
  { id: 21, name: 'Broadcast Producer' },
  { id: 22, name: 'Illustrator' },
  { id: 23, name: 'Event / Experiential Specialist' },
  { id: 24, name: 'Brand Manager' },
  { id: 25, name: 'Communication Specialist' },
  { id: 26, name: 'Community Manager' },
  { id: 27, name: 'Content Marketeer' },
  { id: 28, name: 'Marketing Manager' },
  { id: 29, name: 'Market Research' },
  { id: 30, name: 'CRM Marketeer' },
  { id: 31, name: 'PPC Specialist' },
  { id: 32, name: 'SEO Specialist' },
  { id: 33, name: 'Social Media Consultant' },
  { id: 34, name: 'Animator' },
  { id: 35, name: 'Creative Retoucher' },
  { id: 36, name: 'Design Lead' },
  { id: 37, name: 'UX Designer' },
  { id: 38, name: 'UX Lead' },
  { id: 39, name: 'UX Researcher' },
  { id: 40, name: 'UX Writer' },
  { id: 41, name: 'UI/UX Designer' },
  { id: 42, name: 'Digital Producer' },
  { id: 43, name: 'Video Editor' },
  { id: 44, name: 'Sound Producer' },
  { id: 45, name: 'Sound Engineer' },
  { id: 46, name: 'App Developer' },
  { id: 47, name: 'Web Developer' },
  { id: 48, name: 'Account Director' },
  { id: 49, name: 'Business Director' },
  { id: 50, name: 'Business Consultant' },
  { id: 51, name: 'CMO' },
  { id: 52, name: 'CX Consultant' },
  { id: 53, name: 'Digital Consultant' },
  { id: 54, name: 'Digital Director' },
  { id: 55, name: 'E-commerce Manager' },
  { id: 56, name: 'Event Manager' },
  { id: 57, name: 'Finance Professional' },
  { id: 58, name: 'Innovation Coach' },
  { id: 59, name: 'Sales Representative' },
  { id: 60, name: 'Traffic Manager' },
  { id: 61, name: 'Virtual Assistant' },
  { id: 62, name: 'Copywriter with B2B experience' }
]

export default roleSet
