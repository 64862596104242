import BriefCreator from './components/BriefCreator'

const App = () => {
  return (
    <>
      <BriefCreator/>
    </>

  );
}

export default App;
