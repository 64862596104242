import React from 'react';

interface SuggestedButtonsProps {
    handleSuggestedMessage: (message: string) => void;
}

const SuggestedQueries: React.FC<SuggestedButtonsProps> = ({
        handleSuggestedMessage
    }) => {

    // Suggested Queries
    let q1 = "Give me tips on getting the best work from freelancers";
    let q2 = "What are the qualities I should be looking for in a suitable freelancer?";
    let q3 = "How can I find suitable marketing or creative freelancers in Ireland?";

    return (
        <div className="px-3 py-2 rtl">
            <span className="items-center justify-center w-full text-center relative">
                <h2 className="my-1 justify-center text-white"><b>Suggestions:</b></h2>
                {/*<button className="justify-right rounded-full bg-custom-black px-2 absolute top-0 end-0" onClick={toggleShowSugMsg}>*/}
                {/*    {showSugMsg ? (<p className="text-white">Hide</p>) : (<p className="text-white">Show</p>)}*/}
                {/*</button>*/}
            </span>
            {/*{showSugMsg && (*/}
                <div className="flex flex-col items-stretch space-y-3" >
                    <button className="btn bg-custom-green text-black items-center shadow-md no-transform rounded-3xl" onClick={() => handleSuggestedMessage(q1)}>
                        {q1}
                    </button>
                    <button className="btn bg-custom-green text-black items-center shadow-md no-transform rounded-3xl" onClick={() => handleSuggestedMessage(q2)}>
                        {q2}
                    </button>
                    <button className="btn bg-custom-green text-black items-center shadow-md no-transform rounded-3xl" onClick={() => handleSuggestedMessage(q3)}>
                        {q3}
                    </button>
                </div>
            {/*)}*/}
        </div>
    );
};

export default SuggestedQueries;