interface IBriefInputProps {
    labelText: string,
    placeholder?: string,
    setValue: (value: string) => void;
    onKeyUp?: (e: React.KeyboardEvent) => void;
}


const BriefInput = ({labelText, placeholder, setValue, onKeyUp}: IBriefInputProps) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <div className="w-[100%] input-success">
            <label className="label">
                <span className="label-text text-white">{labelText}</span>
            </label>

            <input
                type="text"
                placeholder={placeholder}
                onChange={handleInputChange}
                onKeyUp={onKeyUp}
                className="min-w-[100%] input input-bordered input-success bg-white shadow text-black rounded-3xl"
            />
            <label className="label"></label>
        </div>
    )
}

export default BriefInput
