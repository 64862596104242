interface EmailProps {
    recipient: string, 
    emailBody: string, 
}

const mailServer = 'https://indielist.ai/email.php';
const mailOptions = (recipient: string, emailBody: any) => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        recipient: recipient,
        message: `${emailBody}`
    })
});

// Take in recipient and emailBody and send it to the backend server
const send = async ({ recipient, emailBody} : EmailProps) => {
    try {
        const clientEmail = async () => await fetch(mailServer, { ...mailOptions(recipient, `Hi ${recipient}! Here's your brief: \n\n ${emailBody}`) });
        const otherEmail = async () => await fetch(mailServer, { ...mailOptions('indiegpt@indielist.ie', `${recipient} created a brief! Brief is shown below: \n\n ${emailBody}`) });

        const promise = await Promise.all([clientEmail(), otherEmail()]);

        if (promise.every(res => res.ok)) return true;
    } catch(err) {
        console.log(err)
    }
    return false;
}

export default send

