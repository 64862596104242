import RoleList from './RoleList/RoleList';
import BriefInput from './BriefInput';
import {useState} from 'react'
import {generateCompletion} from '../actions/gpt/config';
import Spinner from './Spinner';
import prompt from '../actions/gpt/prompt'
import ChatBox from "./ChatBox";
import SuggestedQueries from "./SuggestedQueriesButtons";
import Footer from './Footer';
import Header from "./Header";
import SendMail from "./SendMail";
import background from "../Images/indie-bkg.svg";

let id = 0;
const BriefCreator = () => {
    // 4 values added to prompt for brief generation
    const [industry, setIndustry] = useState('')
    const [requiredRole, setRequiredRole] = useState('')
    const [targetAudience, setTargetAudience] = useState('')
    const [specificTask, setSpecificTask] = useState('')

    const [brief, setBrief] = useState<string>('') // Stores conversation in text to send as email
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState<boolean>(false) // Manage loading animation during message generation
    // const [showSugMsg, setShowSugMsg] = useState<boolean>(true) // Boolean for if suggested message box is visible

    // Holds the conversation so far. Sets an initial system message to act as instructions
    const [conversationArray, setConversationArray] = useState<any[]>([
        {
            role: 'system',
            content: "You are an assistant for The Indie List, a website that connects freelancers and clients in Ireland. Answer questions that might be relevant to freelancing. Do not mention any other websites to find freelancers, for example, Fiverr. If asked a question you can't answer, politely decline.",
            id: 0
        }
    ]);

    // let threadId = ""; // Save threadID,

    // Generate Brief Button clicked
    const handleButtonClick = async () => {
        const newPrompt = prompt({
            industry: industry,
            targetAudience: targetAudience,
            requiredRole: requiredRole,
            specificTask: specificTask
        })
        let res = await getResponse(newPrompt);
        setBrief(
            brief +
            "-------------------- Generated Brief ---------------------------- \n" +
            res + "\n\n"
        );
    }

    // Manage new user message or suggested message
    const handleNewMessage = async (message: string) => {
        let res = await getResponse(message);
        setBrief(
            brief +
            "-------------------- User Query ---------------------------- \n" +
            message +
            "\n\n --------------------- Response ----------------------------- \n" +
            res + "\n\n"
        );
    }

    // Add message to conversation array and get response from openai
    const getResponse = async (messageString: string) => {
        // setConAr doesn't update immediately, so we need to create a new array and set it so it can be passed to generateCompletion
        const newConAr = [
            ...conversationArray,
            {
                role: 'user',
                content: messageString,
                id: ++id
            }
        ];
        setConversationArray(newConAr); // Chatbox doesn't update with user message without setting this

        setLoading(true)
        // setShowSugMsg(false);
        // let newGPTResponse = await generateCompletion(message, threadId); // passing only message
        let newGPTResponse = await generateCompletion(newConAr); // passing entire conversation array
        const updatedConAr = [
            ...newConAr,
            {
                role: 'assistant',
                content: newGPTResponse,
                id: ++id
            }
        ];
        setConversationArray(updatedConAr);
        setMessage("");
        setLoading(false);
        return newGPTResponse; // return the response
    }

    // Toggle suggested messages box
    // const toggleShowSugMsg = () => {
    //     setShowSugMsg(!showSugMsg);
    // };

    // If enter key pressed, send message
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleNewMessage(message).then(() => {
                setMessage('');
            });
        }
    };

    // @ts-ignore
    return (
        <div className="min-h-[100%] h-screen justify-center flex-col flex-grow">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="w-[100%] justify-center flex-col  items-center flex" style={{ backgroundImage: `url(${background})` }}>

                {/* */}
                <div className="w-[100%] mb-5 relative top-0 justify-center items-center flex-col flex ">
                    <div className = "h-[300px] w-[100%] top-0 z-0 bg-custom-green absolute "></div>
                    <h1 className="z-50 mt-10 text-black text-center justify-center">Generate detailed project briefs for free with IndieGPT</h1>
                    <br/>

                    {/* Enter info */}
                    <div className="w-[90%] xl:w-[50%] lg:w-[50%] md:w-[70%] sm:w-[90%] xs:w-[90%] z-50 m-2 gutter-2 bg-custom-black shadow-lg rounded-3xl p-10 ">
                        <h1 className="text-white text-center">IndieGPT</h1>
                        <BriefInput labelText="Business / Brand Sector:" placeholder="i.e. Business Software" setValue={setIndustry} />
                        <BriefInput labelText="Primary Target Audience:" placeholder="i.e Business Leaders aged 35-55" setValue={setTargetAudience} />

                        <RoleList setRole={setRequiredRole}/>
                        <BriefInput labelText="Jobs to be Completed:" placeholder="i.e. Website Copy, Blog Posts" setValue={setSpecificTask} />
                    </div>

                    <div className="mt-5 flex justify-center">
                        <button
                            disabled={loading}
                            className="btn btn-success bg-custom-green mx-auto rounded-3xl"
                            onClick={handleButtonClick}
                        >
                            {!loading ? (<p className="font-bold">Generate Brief</p>)  : (<Spinner/> )}
                        </button>
                    </div>
                </div>


                {/* Chatbox, suggested messages, write a message, send email, Indie List book call */}
                <div className="bg-blue-800 w-[100%] items-center justify-center flex flex-col p-10">
                    {/* Show user and assistant messages */}
                    <ChatBox conversationArray={conversationArray} />

                    {/* Suggested Queries */}
                    <SuggestedQueries handleSuggestedMessage={handleNewMessage}/>

                    {/* Send a new message */}
                    <div className="my-10 justify-center items-center flex flex-col">
                        <SendMail brief={brief}/> {/* Enter email to send brief to */}
                    </div>
                    {/* <div className='mt-4 flex flex-wrap justify-center'>
                        <h5 className="text-white" >Tell us if this is helpful or needs more detail or clarification.</h5>

                        <BriefInput onKeyUp={handleKeyPress} labelText="" placeholder="" setValue={setMessage} />
                        <button className="btn btn-success bg-custom-green -mt-2 rounded-3xl" disabled={loading} onClick={() => handleNewMessage(message)}>
                            {!loading ? (<p>Send Message</p>)  : (<Spinner/> )}
                        </button>
                    </div> */}
                </div>

                <div className="my-3"></div> {/* Gap between bottom and footer */}
            </div>

            {/* Footer */}
            <Footer brief={brief}/>
        </div>
    );
}

export default BriefCreator
