import logo from "../Images/indie-logo.png";

interface FooterProps {
    brief: string,

}

const Footer = ({brief}:FooterProps) => {
    return (
    <div className="bg-black w-[100%] flex flex-col justify-center items-center p-10">
        <img src={logo} alt="Your Logo" className="w-auto max-w-[50%] mr-4 mb-20"/>
        
        <button
            className="btn btn-success mt-2 bg-custom-green rounded-3xl shadow-md justify-end"
            onClick={() => window.open("https://indielist.ie/schedule", "_blank", "noreferrer")}
        >
            Book a call
        </button>

        <p className="my-5 text-white">© 2024 The Indie List. All Rights Reserved</p>

        {/*<IndieListRef requiredRole={requiredRole} /> /!* Book a call with IndieList *!/*/}
    </div>
    );
}

export default Footer;