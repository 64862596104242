import {useEffect, useState} from 'react'
import send from '../actions/email/config'
import Spinner from './Spinner';

interface ISendEmailProps {
    brief:string
}

const SendMail = ({brief}:ISendEmailProps) => {
    const [email, setEmail] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');
    const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
    const [isBoxChecked, setIsBoxChecked] = useState<boolean>(false); // box to allow sharing email with IndieList

    useEffect(() => {
        setTimeout(() => {
            setErrorText('')
        }, 5000)
    }, [errorText]);

    const handleSendEmail = async () => {
        if(isBoxChecked) {
            setIsEmailSending(true);
            try {
                const data = await send({recipient: email, emailBody: brief});
                if (data) {
                    setErrorText('Email sent. Please Check your inbox');
                } else {
                    setErrorText('Failed to send email. Please try again later.');
                }
            } catch (err) {
                setErrorText('An error occured. Please try again.');
            }
            setIsEmailSending(false);
        } else {
            setErrorText('Please check the box to share your email with IndieList');
        }
    };

    return (
        // <>
            <div className="min-w-[100%]">
                <h2 className="text-white text-center">Enter your e-mail in the box below to receive a copy of the brief</h2>
                <div className="w-[100%] flex items-center m-0 my-5 justify-center">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Your Email Address" className="w-[100%] text-black input input-bordered bg-white input-success max-w-xs rounded-l-xl rounded-r-none" />
                    <button className="btn btn-success bg-custom-green rounded-l-none rounded-r-xl"  disabled={isEmailSending} onClick={handleSendEmail}>
                        {isEmailSending ?  (<Spinner/>) : (<p>Send</p>)}
                    </button>
                </div>
                <div>
                    <p className="text-white text-center">{errorText}</p>
                </div>
                <div className="mt-1 ml-2 mb-4 flex items-center justify-center">
                    <input id="default-checkbox" checked={isBoxChecked} type="checkbox" value="" onChange={() => setIsBoxChecked(!isBoxChecked)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-white">I agree to share my email with The Indie List</label>
                </div>
            </div>
        // </>
    )
}

export default SendMail

