let prompt = ({
  industry,
  requiredRole,
  targetAudience,
  specificTask
}: {
  industry: string;
  requiredRole: string;
  targetAudience: string;
  specificTask: string;
}) => `
  I am working in a ${industry} company, I am looking to hire a ${requiredRole}. 
  Our main customers are ${targetAudience}. I need to ${specificTask} 
  Please create a list of the typical key objectives, the channels and the range of tasks I could expect a freelance expert to do for my company.
  `;

  export default prompt
