import React, {useEffect, useRef} from "react";

interface ChatBoxProps {
    conversationArray: Array<any>;
}
const ChatBox: React.FC<ChatBoxProps> = ({ conversationArray }) => {
    const textareaRefs = useRef([]);
    const endOfMessagesRef = useRef<any>(null);
    const resizeAllTextareas = () => {
        textareaRefs.current.forEach((textarea) => {
            //@ts-ignore
            textarea.style.height = 'auto';
            //@ts-ignore
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight i.e display full message
        });
    };
    useEffect(() => {
        // Function to resize all textareas when
        resizeAllTextareas();

        // Add event listener for window resize
        window.addEventListener('resize', resizeAllTextareas);

        if (conversationArray.length > 1) endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', resizeAllTextareas);
        };
    }, [conversationArray]); // Whenever conversationArray changes, useEffect will run


    function ChatBox() {
        return (
            <div className="bg-white rounded-3xl shadow-lg container mb-2 h-[60vh] md:w-[50%] sm:w-[90%] min-h-[30%] xs:min-h-[70vh] overflow-y-auto flex-col">
                {/*<div className="bg-white shadow-md border-b-primary p-1">*/}
                {/*    <h1 className="text-black text-center"><b>IndieGPT</b></h1>*/}
                {/*</div>*/}
                {conversationArray.map((item) => (
                    item.role == "system" ?
                        <></> // Don't show if it's a system message(the instructions given)
                    :
                        item.role === "user" ?
                            // @ts-ignore  // Don't show prompt given to ChatGPT, replace with 'Generate Brief' text
                            item.content.startsWith("\n  I am working in a ") ?
                                <textarea
                                    readOnly
                                    defaultValue="Generate Brief"
                                    //@ts-ignore
                                    ref={(textarea) => (textareaRefs.current[item.id] = textarea)}
                                    className="resize-none rounded-lg w-[70%] p-1 ml-[29%] mt-1 text-chat-blue">
                                </textarea>
                            :
                                // User messages - green, on the right side
                                <div key={item.id}>
                                    {/*<div className="resize-none rounded-lg text-black bg-green-300 shadow w-[70%] p-1 ml-[29%] my-1">{item.content}</div>*/}
                                    <textarea
                                        readOnly
                                        defaultValue={item.content}
                                        //@ts-ignore
                                        ref={(textarea) => (textareaRefs.current[item.id] = textarea)}
                                        className="resize-none rounded-lg w-[70%] p-1 ml-[28%] mt-1 bg-transparent text-chat-blue text-right text-shadow" >
                                    </textarea>
                                </div>

                        :
                            // Assistant messages - blue, on the left side
                            <div key={item.id}>
                                <textarea
                                    readOnly
                                    defaultValue={item.content}
                                    //@ts-ignore
                                    ref={(textarea) => (textareaRefs.current[item.id] = textarea)}
                                    className="resize-none rounded-lg text-black bg-transparent w-[80%] p-1 ml-[1%] my-1">
                                </textarea>
                            </div>
                ))}
                <div ref={endOfMessagesRef} /> {/* Add a div at the end of the messages, scroll to on new message */}
            </div>
        )
    }
    
    return ChatBox();
};

export default ChatBox;