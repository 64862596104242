import OpenAI from 'openai'

async function generateCompletion(conversationArray:any[]) {
    // Need id in array for mapping in ChatBox.tsx, now remove id so conversationArray can be sent to OpenAI API
    let conArNoID = conversationArray.map(({ id, ...rest }) => rest);

    // Reduce messages to initial system instruction and most recent 3 messages (user, assistant, user). Otherwise conversation becomes large and cost to run OpenAI API increases
    conArNoID = [conArNoID[0]].concat(conArNoID.slice(-3));

    if(!process.env.REACT_APP_OPEN_AI_KEYS){
        throw new Error('Open AI API key is not set. Please contact The Indie List if the problem persists')
    }
//
    const openai = new OpenAI({
        dangerouslyAllowBrowser: true,
        apiKey:process.env.REACT_APP_OPEN_AI_KEYS,
    });

    if(!openai) {
        throw new Error('Open AI configuration is not set. Please contact The Indie List if the problem persists')
    }

    try {
        const completion = await openai.chat.completions.create({
            messages : conArNoID,
            model : "gpt-3.5-turbo-0125",
        });

        // Return the assistants response
        return completion.choices[0].message.content;

    } catch(error) {
        throw new Error(`${error}`)
    }
}

export {generateCompletion}
